import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { saga } from './saga';
import { AppState, ContractHandlers } from './types';

export const initialState: AppState = {
    contractHandlers: null,
};

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        initContractHandlers() {},
        gotContractHandlers(state, action: PayloadAction<ContractHandlers | null>) {
            state.contractHandlers = action.payload;
        },
    },
});

export const { actions: AppActions, reducer } = slice;

export const useAppSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga });
    return { actions: slice.actions };
};

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from '../styles/global-styles';

import { LandingPage } from './pages/LandingPage/Loadable';
import { DefiApp } from './pages/DefiApp/Loadable';
import { LoginCallback } from './components/LoginCallBack';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { TosPage } from './pages/TosPage/Loadable';
import { useTranslation } from 'react-i18next';
import { URL } from 'utils/constant';
import { useDispatch } from 'react-redux';
import { useUserSlice } from './components/NavBar/slice';
import { useMetamaskListener } from './components/NavBar/useMetamaskListener';
import { useMountEffect } from '../hook/useMountEffect';
import { useAppSlice } from 'app/slice';
import CheckTos from 'app/components/CheckTos';

export function App() {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { actions: userActions } = useUserSlice();
    const { actions: appActions } = useAppSlice();

    useMetamaskListener();

    useMountEffect(() => {
        (async () => {
            await dispatch(appActions.initContractHandlers());
            await dispatch(userActions.getSession());
        })();
    });

    return (
        <BrowserRouter>
            <Helmet
                titleTemplate="WAXDeFi - %s"
                defaultTitle="WAXDeFi"
                htmlAttributes={{ lang: i18n.language }}
            >
                <meta name="description" content="A DeFi application." />
            </Helmet>
            <Switch>
                <Route path={process.env.PUBLIC_URL + URL.Tos} component={TosPage} />
                <Route path={process.env.PUBLIC_URL + URL.Login} component={LoginCallback} />
                <Route path={process.env.PUBLIC_URL + URL.DefiApp}>
                    <CheckTos>
                        <DefiApp />
                    </CheckTos>
                </Route>
                <Route exact path={process.env.PUBLIC_URL + URL.LandingPage}>
                    <CheckTos>
                        <LandingPage />
                    </CheckTos>
                </Route>
                <Route component={NotFoundPage} />
            </Switch>
            <GlobalStyle />
        </BrowserRouter>
    );
}

import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import { CONTRACTS } from './constant';
import { decimalUtil } from './helper';
import wLog from './logger';

const { to18Decimals, to8Decimals } = decimalUtil();

export type epoch = {
    currentEpoch: {
        id: number;
    };
    nextEpoch: {
        id: number;
        startTimestamp: number;
    };
};

class Web3EthContractHandler {
    abiPath: string;
    contractName: string;
    eth: any; /* temp workaround for window.etherum */
    contract: Contract | null;

    constructor(eth: any, abiPath: string, contractName: string) {
        this.abiPath = abiPath;
        this.contractName = contractName;
        this.eth = eth;
        this.contract = null;
    }

    async initContract() {
        this.contract = await this.fromApi();
        return this;
    }

    async getContract() {
        return this.contract || (await this.fromApi());
    }

    async fromApi() {
        const { abi } = await fetch(this.abiPath).then(response => response.json());

        const web3 = new Web3(this.eth);
        return new web3.eth.Contract(abi, this.contractName);
    }
}

class WaxeEthContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, '/json/IERC20.json', CONTRACTS.WAXE_ETH_TOKEN);
    }

    async getBalance(selectedAddress: string): Promise<number> {
        const contract = await this.getContract();
        try {
            const waxeEthBalance = await contract.methods.balanceOf(selectedAddress).call();
            return waxeEthBalance / Math.pow(10, 18);
        } catch (err: any) {
            // console.error(
            //     `[WaxeEthContractHandler]cannot get balanceOf::${selectedAddress}`,
            //     err.message,
            // );
            return 0;
        }
    }

    async hasStakedAlready(amount: string, selectedAddress: string): Promise<boolean> {
        const contract = await this.getContract();

        let ret = await contract.methods.allowance(selectedAddress, CONTRACTS.WEAP_BRIDGE).call();
        let floatAmount = parseFloat(amount);
        return parseFloat(ret) === floatAmount && floatAmount > 0;
    }

    async waxeApproveStake(amount: string, selectedAddress: string) {
        const contract = await this.getContract();
        return await contract.methods
            .approve(CONTRACTS.WEAP_BRIDGE, amount)
            .send({ from: selectedAddress });
    }

    async totalSupply() {
        const contract = await this.getContract();
        return await contract.methods.totalSupply().call();
    }
}

class WaxeContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/IERC20.json`, CONTRACTS.WAXE_TOKEN);
    }

    async getWaxeBalance(selectedAddress): Promise<number> {
        const contract = await this.getContract();
        try {
            const waxeBalance = await contract.methods.balanceOf(selectedAddress).call();
            return waxeBalance / 100_000_000;
        } catch (err: any) {
            // console.error(
            //     `[WaxeContractHandler] cannot get balanceOf::${selectedAddress}`,
            //     err.message,
            // );
            return 0;
        }
    }

    async approveErc20(amount, selectedAddress: string) {
        const contract = await this.getContract();
        amount = '' + amount;
        let allowance = await this.getAllowance(selectedAddress);
        if (allowance === amount) {
            // User has already approved a sufficient amount
            return;
        }
        return await contract.methods
            .approve(CONTRACTS.ETH_BRIDGE, amount)
            .send({ from: selectedAddress });
    }

    async getAllowance(selectedAddress: string) {
        const contract = await this.getContract();
        return contract.methods.allowance(selectedAddress, CONTRACTS.ETH_BRIDGE).call();
    }
}

class WaxpErc20ContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/IERC20.json`, CONTRACTS.WAXP_ERC20_TOKEN);
    }

    async getWaxpBalance(selectedAddress): Promise<number> {
        const contract = await this.getContract();
        try {
            const waxpBalance = await contract.methods.balanceOf(selectedAddress).call();
            return waxpBalance / 100_000_000;
        } catch (err: any) {
            // console.error(
            //     `[WaxpErc20ContractHandler] cannot get balanceOf::${selectedAddress}`,
            //     err.message,
            // );
            return 0;
        }
    }

    async approveErc20(amount, selectedAddress: string) {
        const contract = await this.getContract();
        amount = '' + amount;
        let allowance = await this.getAllowance(selectedAddress);
        if (allowance === amount) {
            // User has already approved a sufficient amount
            return;
        }
        return await contract.methods
            .approve(CONTRACTS.ETH_BRIDGE, amount)
            .send({ from: selectedAddress });
    }

    async getAllowance(selectedAddress: string) {
        const contract = await this.getContract();
        return contract.methods.allowance(selectedAddress, CONTRACTS.ETH_BRIDGE).call();
    }
}

class EthBridgeContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/EthWAXBridge.json`, CONTRACTS.ETH_BRIDGE);
    }

    async transferErc20(username, amount, selectedAddress) {
        const contract = await this.getContract();

        return await contract.methods
            .transfer(CONTRACTS.WAXE_TOKEN, username, '' + amount)
            .send({ from: selectedAddress });
    }

    async getPastEvents(fromBlock: number, ethAddress: string) {
        const contract = await this.getContract();
        return await contract.getPastEvents('TransferToWax', {
            fromBlock,
            filter: { _from: [ethAddress] },
        });
    }

    async transfer(tx_hash: string) {
        const contract = await this.getContract();
        return await contract.methods.transfers(tx_hash).call();
    }

    async claimTransaction(amount, txHash, signature, tokenAddress, selectedAddress) {
        const contract = await this.getContract();
        if (this.eth && selectedAddress) {
            return await contract.methods
                .release(tokenAddress, this.eth.selectedAddress, amount, txHash, signature)
                .send({ from: this.eth.selectedAddress });
        }
    }
}
/**
 * usage
 */
//  const { ethereum } = window as any;
//  const WaxeContract = new WaxeContractHandler(ethereum, ethereum.selectedAddress);
//  const WaxeBalance = await WaxeContract.getBalance();

class WaxgContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/WAXGERC20UpgradeSafe.json`, CONTRACTS.WAXG_TOKEN);
    }

    async getWaxgBalance(selectedAddress: string): Promise<number> {
        const contract = await this.getContract();
        try {
            const waxgBalance = await contract.methods.balanceOf(selectedAddress).call();
            return waxgBalance / 100_000_000;
        } catch (err: any) {
            // console.error(
            //     `[WaxgContractHandler]cannot get balanceOf::${selectedAddress}`,
            //     err.message,
            // );
            return 0;
        }
    }

    async escrow() {
        const contract = await this.getContract();
        return await contract.methods.escrow().call();
    }

    async totalSupply() {
        const contract = await this.getContract();
        return await contract.methods.totalSupply().call();
    }

    async balanceOf(address: string) {
        const contract = await this.getContract();
        return await contract.methods.balanceOf(address).call();
    }

    async estimateWaxgBurnEthRewards(selectedAddress: string) {
        const contract = await this.getContract();
        if (!!selectedAddress) {
            try {
                const waxgBalance = await contract.methods.balanceOf(selectedAddress).call();
                const { ethReward, waxpReward } = await contract.methods
                    .estimateReward(waxgBalance)
                    .call();
                const ethRewardFormatted = !!ethReward ? decimalUtil().to18Decimals(ethReward) : 0;
                const waxpRewardFormatted = !!waxpReward
                    ? decimalUtil().to8Decimals(waxpReward)
                    : 0;
                return [ethRewardFormatted, waxpRewardFormatted];
            } catch (e) {
                return [0, 0];
            }
        } else {
            return [0, 0];
        }
    }

    async burnWaxg(amount: number, selectedAddress: string) {
        const contract = await this.getContract();
        return contract.methods.burn(amount).send({ from: selectedAddress });
    }
}

class WeapContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/WEAP.json`, CONTRACTS.WEAP_BRIDGE);
    }

    async getWAXGUnclaimedRewards(
        selectedAddress: string,
    ): Promise<{ waxg: string; eth: string; waxp: string }> {
        const contract = await this.getContract();
        try {
            const rewardBalances = await contract.methods.rewardOf(selectedAddress).call();

            const unclaimed = {
                waxg: to8Decimals(rewardBalances.waxgReward),
                eth: to18Decimals(rewardBalances.ethReward),
                waxp: to8Decimals(rewardBalances.waxpReward),
            };

            return unclaimed;
        } catch (err: any) {
            // console.error(
            //     `[WeapContractHandler]cannot get balanceOf::${selectedAddress}`,
            //     err.message,
            // );
            return { waxg: '0', eth: '0', waxp: '0' };
        }
    }

    async getPercentages(selectedAddress): Promise<{
        currentEpochRewardsPercent: number;
        nextEpochRewardsPercent: number;
    }> {
        const contract = await this.getContract();
        const myStake = await contract.methods.stakes(selectedAddress).call();
        const lastEpoch = await contract.methods.getLastEpoch().call();

        const currentEpochRewardsNumerator =
            +myStake.allocatedUpToEpochID <= +lastEpoch.epochID
                ? +myStake.active + +myStake.pending
                : +myStake.active;
        const currentEpochRewardsPercent =
            +lastEpoch.totalStaked > 0
                ? (100 * currentEpochRewardsNumerator) / lastEpoch.totalStaked
                : 0;
        const nextEpochRewardsPercent =
            +lastEpoch.totalStaked + +lastEpoch.newStaked > 0
                ? (100 * (+myStake.active + +myStake.pending)) /
                  (+lastEpoch.totalStaked + +lastEpoch.newStaked)
                : 0;

        return {
            currentEpochRewardsPercent,
            nextEpochRewardsPercent,
        };
    }

    async getEpochInfo(): Promise<epoch> {
        let res = {
            currentEpoch: {
                id: 0,
            },
            nextEpoch: {
                id: 0,
                startTimestamp: 0,
            },
        };
        const contract = await this.getContract();
        try {
            let nextEpoch = await contract.methods.getNextEpochStartTime().call();
            let currentEpochID = await contract.methods.getCurrentEpochID().call();
            res = {
                currentEpoch: {
                    id: parseInt(currentEpochID),
                },
                nextEpoch: {
                    id: parseInt(nextEpoch.nextEpochID),
                    startTimestamp: parseInt(nextEpoch.nextStartTimestamp),
                },
            };
        } catch (e: any) {
            wLog.log('getEpochInfo: ', e);
        }
        return res;
    }

    async waxgClaim(ethAress: string) {
        const contract = await this.getContract();
        return contract.methods.claim().send({ from: ethAress });
    }

    async getStakedBalance(selectedAddress: string): Promise<number> {
        const contract = await this.getContract();
        const stakeData = await contract.methods.stakes(selectedAddress).call();
        const amount = +stakeData.active + +stakeData.pending;
        const decimals = to18Decimals(`${amount}`);
        return parseFloat(decimals);
    }

    async waxeStake(amount, selectedAddress) {
        const contract = await this.getContract();
        return await contract.methods.stake(amount).send({ from: selectedAddress });
    }

    async waxeUnStake(amount, selectedAddress) {
        const contract = await this.getContract();
        return await contract.methods.unstake(amount).send({ from: selectedAddress });
    }

    async stakes(selectedAddress: string) {
        const contract = await this.getContract();
        return await contract.methods.stakes(selectedAddress).call();
    }

    async getLastEpoch() {
        const contract = await this.getContract();
        return await contract.methods.getLastEpoch().call();
    }
}

class EthContractHandler {
    address: string;
    eth: any;

    constructor(eth: any, address: string) {
        this.address = address;
        this.eth = eth;
    }

    async getEthBalance(): Promise<number> {
        const web3 = new Web3(this.eth);
        const ethBalance = await web3.eth.getBalance(this.address);
        const fromWei = web3.utils.fromWei(ethBalance, 'ether');
        return parseFloat(fromWei);
    }
}

class abiContract {
    private static instance: abiContract;
    private abiWaxeIERC20: AbiItem[];
    private abiWaxgERC20: AbiItem[];

    constructor() {
        if (abiContract.instance) {
            throw new Error('Error - use abiContract.getInstance()');
        }
        abiContract.instance = this;

        this.abiWaxeIERC20 = (async (): Promise<AbiItem[]> => {
            let { abi } = await fetch('/json/IERC20.json').then(response => response.json());
            return abi;
        })() as unknown as AbiItem[];

        this.abiWaxgERC20 = (async (): Promise<AbiItem[]> => {
            let { abi } = await fetch('/json/WAXGERC20UpgradeSafe.json').then(response =>
                response.json(),
            );
            return abi;
        })() as unknown as AbiItem[];
    }

    static getInstance(): abiContract {
        abiContract.instance = abiContract.instance || new abiContract();
        return abiContract.instance;
    }

    getAbiWaxeIERC20(): AbiItem[] {
        return this.abiWaxeIERC20;
    }

    getAbiWaxgIERC20(): AbiItem[] {
        return this.abiWaxgERC20;
    }
}

class UniSwapERC20ContractHandler extends Web3EthContractHandler {
    constructor(eth: any) {
        super(eth, `/json/UniSwapERC20.json`, CONTRACTS.WAXE_ETH_TOKEN);
    }

    async getReserves() {
        const contract = await this.getContract();
        return contract.methods.getReserves().call();
    }

    async totalSupply() {
        const contract = await this.getContract();
        return contract.methods.totalSupply().call();
    }

    async balanceOf(address: string) {
        const contract = await this.getContract();
        return contract.methods.balanceOf(address).call();
    }
}

export {
    abiContract,
    Web3EthContractHandler,
    WaxeEthContractHandler,
    WaxeContractHandler,
    WaxgContractHandler,
    EthContractHandler,
    WeapContractHandler,
    EthBridgeContractHandler,
    UniSwapERC20ContractHandler,
    WaxpErc20ContractHandler,
};

import styled from 'styled-components/macro';
import { StyleConstants, ScreenSizes } from 'styles/StyleConstants';

import background from './assets/background.png';
import background_head from './assets/background_head.png';

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Wrapper = styled.div`
    height: calc(100vh - ${StyleConstants.NAV_BAR_HEIGHT} + 650px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 814px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -5px;

    .convert-max {
        font-weight: normal;
        cursor: pointer;
        color: #7d70f6;
        text-decoration: underline;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        height: calc(100% - ${StyleConstants.NAV_BAR_HEIGHT});
    }
`;

export const TitleLayout = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 250px;

    background-image: url(${background_head});
    background-size: cover;
    background-repeat: no-repeat;

    .title-div-1 {
        width: 100%;
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        line-height: 56px;
        text-align: center;
        color: #ffffff;
    }

    .title-div-2 {
        max-width: 700px;
        width: 100%;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
    }

    .title-div-1 span {
        color: #c8c3f9;
    }

    @media (max-width: ${ScreenSizes.small}) {
        .title-div-1 {
            font-size: 36px;
            line-height: 46px;
        }

        .title-div-2 {
            font-size: 12px;
            line-height: 20px;
            max-width: unset;
        }
    }
`;

export const InforLayout = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
`;

export const StepWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 100px 0px;

    .step-div-1 {
        max-width: 300px;
        width: 250px;
    }

    .step-div-2 {
        width: 550px;
        margin-left: 25px;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        flex-direction: column;
        align-items: center;
        padding: 30px 0px;

        .step-div-1 {
            max-width: 400px;
            width: 90%;
        }

        .step-div-2 {
            width: 90%;
            margin-top: 25px;
            margin-left: 0px;
        }
    }
`;

export const StepLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-stat;
    align-items: center;
    width: 100%;

    .stepmenu-div-1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
    }

    .stepmenu-div-1:hover {
        cursor: pointer;
        .stepmenu-div-4 {
            color: #ffffff;
            transform: scale(1.1);
            transition: transform 0.5s;
        }
        svg {
            transform: scale(1.3);
            transition: transform 0.5s;
        }
    }

    .stepmenu-div-1.border {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .stepmenu-div-dot {
        width: 8px;
        height: 8px;
        background: #ff774a;
        border-radius: 100%;
        margin-left: 8px;
        margin-bottom: 6px;
    }

    .stepmenu-div-2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .stepmenu-div-3 {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #371d4a;
        margin-right: 16px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
    }

    .stepmenu-div-4 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #c8c3f9;
    }

    .stepmenu-div-4.active {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
    }
`;

export const BlockLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 100px 0px;
    margin-top: 40px;

    .block-box-div-1 {
        width: 80%;
        background: #ffffff;
        box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        max-width: 600px;
        height: 300px;
        padding: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-style: normal;
        font-size: 18px;
        line-height: 24px;
    }
`;

import { useEffect } from 'react';
import axios from 'axios';

export const LoginCallback = (): null => {
    useEffect(() => {
        const tryLogin = async () => {
            try {
                await axios(`${process.env.REACT_APP_DEFI_API}/login`, {
                    method: 'GET',
                    withCredentials: true,
                });
            } catch (error: any) {
                const err = error?.response?.data;
                if (err) {
                    if (err?.error === 'InvalidAuthToken') {
                        //redirect to api login to login
                        window.location.href = err.message;
                    }
                }
            }
        };

        tryLogin();
    }, []);

    return null;
};

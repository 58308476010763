import { TokenBalance } from 'utils/helper';
export interface UserState {
    username: string;
    loadingWcw: boolean; //loading for wax cloud wallet
    loadingWallets: boolean; //loading for 3rd party wallet: Metamask, etc
    error?: ErrorType | null;
    walletData: UserData | null;
    metamaskData: MetaMaskData | null;
    isOpenLoginModal: boolean;
}

export enum ErrorType {
    RESPONSE_ERROR = 1,
    USER_NOT_FOUND = 2,
    USERNAME_EMPTY = 3,
    UNAUTHORIZED = 4,
}

export interface UserData {
    username: string;
    email: string;
    balance: TokenBalance;
    publicKeys: Array<string>;
}

export interface MetaMaskData {
    ethWindow?: any;
    ethWc?: any;
    ethAddress: string;
    eth?: TokenBalance;
    waxe?: TokenBalance;
    waxpErc20?: TokenBalance;
    waxg?: TokenBalance;
    waxeEth?: TokenBalance;
    stakedWaxeEth?: TokenBalance;
    isWalletConnect?: boolean;
}

export interface SessionType {
    verified: boolean;
    accountName: string;
    publicKeys: Array<string>;
}
/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'UserState' keyword.
*/
export type ContainerUserState = UserState;

import * as wLog from 'loglevel';
import { LOG_LEVEL } from './constant';

/**
 * 'trace'
 * 'debug'
 * 'info'
 * 'warn'
 * 'error'
 * 'silent'
 */
switch (LOG_LEVEL) {
    case 'trace':
        wLog.setLevel('trace');
        break;
    case 'debug':
        wLog.setLevel('debug');
        break;
    case 'info':
        wLog.setLevel('info');
        break;
    case 'warn':
        wLog.setLevel('warn');
        break;
    case 'error':
        wLog.setLevel('error');
        break;
    case 'silent':
        wLog.setLevel('silent');
        break;
}
export default wLog;

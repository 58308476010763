import { call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { getWaxBalance } from 'utils/rpcHandler';
import { TokenBalance } from 'utils/helper';
import { selectContractHandlers } from 'app/slice/selectors';
import {
    WaxeContractHandler,
    WaxeEthContractHandler,
    WaxgContractHandler,
    EthContractHandler,
    WeapContractHandler,
    WaxpErc20ContractHandler,
} from 'utils/contractHandlerV2';
import { UserActions as actions } from '.';
import { ErrorType, MetaMaskData, SessionType, UserData } from './types';
import { switchNetwork } from 'utils/helper';
import { CHAIN_ID, TOKEN_DECIMAL } from 'utils/constant';
import { selectMetaMaskData } from './selectors';

export function* getSession() {
    try {
        const requestURL = `${process.env.REACT_APP_DEFI_API}/session`;
        const sessions: SessionType = yield call(request, requestURL, { credentials: 'include' });

        let waxpBalance = 0;
        if (sessions?.accountName) {
            waxpBalance = yield call(getWaxBalance, sessions.accountName);
        }

        const data: UserData = {
            username: sessions.accountName,
            balance: new TokenBalance('WAXP', waxpBalance, TOKEN_DECIMAL.WAXP_DECIMALS),
            email: '',
            publicKeys: sessions.publicKeys,
        };

        yield put(actions.gotSession(data));
    } catch (error: any) {
        //401 : Unauthorized)
        if (401 === error.response?.status) {
            yield put(actions.errorSign(ErrorType.UNAUTHORIZED));
        } else {
            yield put(actions.errorSign(ErrorType.RESPONSE_ERROR));
        }
        yield put(actions.signOut);
    }
}

export function* getMetamask() {
    const contractHandlers = yield select(selectContractHandlers);
    // const metaMaskData = yield select(selectMetaMaskData);
    const ethereum = !!window.wc && window.wc.connected ? window.wc : window.ethereum;
    try {
        if (!!contractHandlers && !!ethereum) {
            const addresses = yield call(requestAccount, ethereum);
            const selectedAddress = !!addresses && addresses.length ? addresses[0] : '';
            if (!!selectedAddress) {
                switchNetwork(ethereum, CHAIN_ID);
                const ethBalance = yield call(getETHBalance, ethereum, selectedAddress);
                const waxe = yield call(
                    getWaxeBalance,
                    contractHandlers.waxeContractHandler,
                    selectedAddress,
                );
                const waxg = yield call(
                    getwaxgBalance,
                    contractHandlers.waxgContractHandler,
                    selectedAddress,
                );
                const waxeEth = yield call(
                    getWaxeEthBalance,
                    contractHandlers.waxeEthContractHandler,
                    selectedAddress,
                );
                const stakedWaxeEth = yield call(
                    getWaxeEthStakedBalance,
                    contractHandlers.weapContractHandler,
                    selectedAddress,
                );
                const waxpErc20 = yield call(
                    getWaxpErc20Balance,
                    contractHandlers.waxpErc20ContractHandler,
                    selectedAddress,
                );
                const data: MetaMaskData = {
                    ethWindow: window.ethereum,
                    ethAddress: selectedAddress,
                    ethWc: window.wc,
                    isWalletConnect: !!window.wc && window.wc.connected,
                    eth: new TokenBalance('ETH', ethBalance, TOKEN_DECIMAL.ETH_DECIMALS),
                    waxe: new TokenBalance('WAXE', waxe, TOKEN_DECIMAL.WAXE_DECIMALS),
                    waxpErc20: new TokenBalance('WAXP', waxpErc20, TOKEN_DECIMAL.WAXP_DECIMALS),
                    waxg: new TokenBalance('WAXG', waxg, TOKEN_DECIMAL.WAXG_DECIMALS),
                    waxeEth: new TokenBalance('WAXE-ETH', waxeEth, TOKEN_DECIMAL.WAXE_ETH_DECIMALS),
                    stakedWaxeEth: new TokenBalance(
                        'WAXE-ETH',
                        stakedWaxeEth,
                        TOKEN_DECIMAL.WAXE_ETH_DECIMALS,
                    ),
                };
                yield put(actions.metaMaskConnected(data));
            } else {
                yield put(actions.metaMaskDisConnected());
            }
        } else {
            yield put(actions.metaMaskDisConnected());
        }
    } catch (error) {
        yield put(actions.metaMaskDisConnected());
    }
}

async function getETHBalance(ethereum: any, selectedAddress: string): Promise<number | string> {
    const ethContract = new EthContractHandler(ethereum, selectedAddress);
    return ethContract.getEthBalance();
}

async function getWaxeBalance(
    handler: WaxeContractHandler,
    selectedAddress: string,
): Promise<number> {
    return handler.getWaxeBalance(selectedAddress);
}
async function getWaxpErc20Balance(
    handler: WaxpErc20ContractHandler,
    selectedAddress: string,
): Promise<number> {
    return handler.getWaxpBalance(selectedAddress);
}

async function getwaxgBalance(
    handler: WaxgContractHandler,
    selectedAddress: string,
): Promise<number> {
    return handler.getWaxgBalance(selectedAddress);
}

async function getWaxeEthBalance(
    handler: WaxeEthContractHandler,
    selectedAddress: string,
): Promise<number> {
    return handler.getBalance(selectedAddress);
}

async function getWaxeEthStakedBalance(
    handler: WeapContractHandler,
    selectedAddress: string,
): Promise<number> {
    return handler.getStakedBalance(selectedAddress);
}

async function requestAccount(ethereum: any) {
    const selectedAddress = await ethereum.request({ method: 'eth_accounts' });
    return selectedAddress;
    // let selectedAddress: string = '';
    // if (!!ethereum) {
    //     const web3 = new Web3(ethereum);
    //     web3.eth.getAccounts(function (err, accounts) {
    //         if (err != null) {
    //             console.error('An error occurred: ', err);
    //         } else if (accounts.length == 0) {
    //             console.log('User is not logged in to MetaMask');
    //         } else {
    //             console.log('User is logged in to MetaMask');
    //             selectedAddress = accounts[0];
    //         }
    //     });
    //     selectedAddress = ethereum.selectedAddress;
    // }
    // console.log(`sa`, selectedAddress);

    // return selectedAddress;
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
    // Watches for loadRepos actions and calls getRepos when one comes in.
    // By using `takeLatest` only the result of the latest API call is applied.
    // It returns task descriptor (just like fork) so we can continue execution
    // It will be cancelled automatically on component unmount
    yield takeLatest(actions.getSession.type, getSession);
    yield takeLatest(actions.getMetamask.type, getMetamask);
}

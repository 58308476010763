export enum URL {
    LandingPage = '/',
    Login = '/login',
    DefiApp = '/app',
    SwapPage = '/swap',
    AddPage = '/add',
    StakePage = '/stake',
    ClaimPage = '/claim',
    Tos = '/tos',
    TosContent = '/tos/content',
}

const CONTRACTS = {
    WAX_BRIDGE: process.env.REACT_APP_WAX_BRIDGE_CONTRACT || 'bridge.wax',
    WAX_ETH_TOKEN: process.env.REACT_APP_WAX_ETH_TOKEN_CONTRACT || 'eth.token',
    WAXE_TOKEN:
        process.env.REACT_APP_WAXE_TOKEN_CONTRACT || '0x6659F01B44B1fdF55Bd8D42B8B50238E6F47aBD3',
    WAXG_TOKEN:
        process.env.REACT_APP_WAXG_TOKEN_CONTRACT || '0x1E5D2CA11ca5857dd220Db648fEdAA6BcD89E1dc',
    WAXP_ERC20_TOKEN:
        process.env.REACT_APP_WAXP_ERC20_TOKEN_CONTRACT ||
        '0x2A79324c19Ef2B89Ea98b23BC669B7E7c9f8A517',
    ETH_BRIDGE:
        process.env.REACT_APP_ETH_BRIDGE_CONTRACT || '0x1dbB9924b5b961e671A86EE45Fd949B23aE441C7',
    WAXE_ETH_TOKEN:
        process.env.REACT_APP_WAXE_ETH_TOKEN_CONTRACT ||
        '0x0ee0cb563a52ae1170ac34fbb94c50e89adde4bd',
    UNISWAP_WAXE_ETH_TOKEN:
        process.env.REACT_APP_UNISWAP_WAXE_ETH_TOKEN_CONTRACT ||
        '0x0ee0cb563a52ae1170ac34fbb94c50e89adde4bd',
    WEAP_BRIDGE:
        process.env.REACT_APP_WEAP_BRIDGE_CONTRACT || '0xBFFF103D201d2f9db7012684CAb0c37c7323a92D',
    LP_ADDRESS: process.env.REACT_APP_LP_ADDRESS || '0x0290FB167208Af455bB137780163b7B7a9a10C16',
};

const WAX_CHAIN_API = process.env.REACT_APP_WAX_CHAIN_API || 'https://stg2-chain.widney.io';
const UNISWAP_V2_SUBGRAPH =
    process.env.REACT_APP_UNISWAP_V2_SUBGRAPH ||
    'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2';
const MAX_APR = process.env.REACT_APP_MAX_APR || '68%';
const DAPP_INTEGRATION = process.env.REACT_APP_DAPP_INTEGRATION || '1800+';
const WALLETCONNECT_INFURA_ID =
    process.env.REACT_APP_WALLETCONNECT_INFURA_ID || '922d7d90864143438bc5e71dd54d5e95';

const CONTENTS = {
    WAX_TOKENOMICS_URL:
        process.env.REACT_APP_WAX_TOKENOMICS_URL ??
        'https://medium.com/wax-io/wax-tokenomics-defi-is-now-live-heres-how-to-participate-ded30c7c4134',
};
const CHAIN_ID = process.env.REACT_APP_ETHEREUM_CHAIN_ID
    ? parseInt(process.env.REACT_APP_ETHEREUM_CHAIN_ID)
    : 1;

const TOKEN_DECIMAL = {
    WAXE_ETH_DECIMALS: 18,
    ETH_DECIMALS: 18,
    WAXP_DECIMALS: 8,
    WAXG_DECIMALS: 8,
    WAXE_DECIMALS: 8,
};

const EMITTING_EVENTS = {
    SHOULD_LOAD_TVL: 'SHOULD_LOAD_TVL',
};

const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL ?? 'silent';
const ENABLE_MULTICHAIN = process.env.REACT_APP_ENABLE_MULTICHAIN === 'true';

export {
    CONTRACTS,
    WAX_CHAIN_API,
    UNISWAP_V2_SUBGRAPH,
    MAX_APR,
    DAPP_INTEGRATION,
    CONTENTS,
    CHAIN_ID,
    TOKEN_DECIMAL,
    EMITTING_EVENTS,
    WALLETCONNECT_INFURA_ID,
    LOG_LEVEL,
    ENABLE_MULTICHAIN,
};

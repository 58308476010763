import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = state => state.app || initialState;

export const selectContractHandlers = createSelector(
    [selectDomain],
    AppState => AppState.contractHandlers,
);

import { call, put, takeLatest } from 'redux-saga/effects';

import {
    WaxeContractHandler,
    WaxeEthContractHandler,
    WaxgContractHandler,
    WeapContractHandler,
    EthBridgeContractHandler,
    WaxpErc20ContractHandler,
} from 'utils/contractHandlerV2';
import { AppActions as actions } from '.';
import { UserActions } from 'app/components/NavBar/slice';

export function* initContractHandler() {
    const ethereum = !!window.wc && window.wc.connected ? window.wc : window.ethereum;
    if (!ethereum) return null;
    const waxeContractHandler = yield call(getWaxe, ethereum);
    const waxpErc20ContractHandler = yield call(getWaxpErc20, ethereum);
    const waxgContractHandler = yield call(getWaxg, ethereum);
    const waxeEthContractHandler = yield call(getWaxeEth, ethereum);
    const weapContractHandler = yield call(getWeap, ethereum);
    const ethBridgeContractHandler = yield call(getEthBridge, ethereum);
    yield put(
        actions.gotContractHandlers({
            waxeContractHandler,
            waxpErc20ContractHandler,
            waxgContractHandler,
            waxeEthContractHandler,
            weapContractHandler,
            ethBridgeContractHandler,
        }),
    );
    yield put(UserActions.getMetamask());
}

async function getWaxe(ethereum: any): Promise<WaxeContractHandler> {
    const handler = new WaxeContractHandler(ethereum);
    return await handler.initContract();
}

async function getWaxpErc20(ethereum: any): Promise<WaxpErc20ContractHandler> {
    const handler = new WaxpErc20ContractHandler(ethereum);
    return await handler.initContract();
}

async function getWaxg(ethereum: any): Promise<WaxgContractHandler> {
    const handler = new WaxgContractHandler(ethereum);
    return await handler.initContract();
}

async function getWaxeEth(ethereum: any): Promise<WaxeEthContractHandler> {
    const handler = new WaxeEthContractHandler(ethereum);
    return await handler.initContract();
}

async function getEthBridge(ethereum: any): Promise<EthBridgeContractHandler> {
    const handler = new EthBridgeContractHandler(ethereum);
    return await handler.initContract();
}

async function getWeap(ethereum: any): Promise<WeapContractHandler> {
    const handler = new WeapContractHandler(ethereum);
    return await handler.initContract();
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
    // Watches for loadRepos actions and calls getRepos when one comes in.
    // By using `takeLatest` only the result of the latest API call is applied.
    // It returns task descriptor (just like fork) so we can continue execution
    // It will be cancelled automatically on component unmount
    yield takeLatest(actions.initContractHandlers.type, initContractHandler);
}

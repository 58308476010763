import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import wLog from 'utils/logger';
import { useUserSlice } from './slice';
import { MetaMaskData } from './slice/types';

const eth = window.wc ?? window.ethereum;

/**
 * Custom hook to listen accountsChanged|disconnect event from metamask
 * When listened MetaMaskData will be reassigned
 */
export const useMetamaskListener = () => {
    const dispatch = useDispatch();
    const { actions } = useUserSlice();

    const setAccount = () => {
        const data: MetaMaskData = {
            ethAddress: eth?.selectedAddress ?? '',
            ethWindow: window.ethereum,
            ethWc: window.wc,
            isWalletConnect: !!window.wc,
        };
        dispatch(actions.metaMaskConnected(data));
        dispatch(actions.getMetamask());
    };
    useEffect(
        () => {
            if (!eth) {
                // Nothing to do here... no ethereum provider found
                return;
            }
            setAccount();

            const accountWasChanged = () => {
                setAccount();
                wLog.log('accountWasChanged');
            };

            const clearAccount = () => {
                setAccount();
                wLog.log('clearAccount');
            };

            const chainWasChanged = () => {
                wLog.log('chainWasChanged');
                dispatch(actions.getMetamask());
            };

            eth.on('accountsChanged', accountWasChanged);
            eth.on('connect', () => {
                wLog.log('connected');
            });
            eth.on('disconnect', clearAccount);
            eth.on('chainChanged', chainWasChanged);

            return () => {
                if (!!eth.off) {
                    // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
                    eth.off('accountsChanged', accountWasChanged);
                    eth.off('connect', () => {});
                    eth.off('disconnect', clearAccount);
                    eth.off('chainChanged', chainWasChanged);
                }
            };
        },
        [
            /* empty array to avoid re-request on every render, but if you have state related to a connect button, put here */
        ],
    );
};

const lightTheme = {
    primary: '#7D70F6',
    text: 'rgba(58,52,51,1)',
    textSecondary: 'rgba(58,52,51,0.7)',
    background: 'rgba(255,255,255,1)',
    backgroundVariant: 'rgba(251,249,249,1)',
    border: 'rgba(58,52,51,0.12)',
    borderLight: 'rgba(58,52,51,0.05)',
    navColor: '#F78E1E',
};

const darkTheme: Theme = {
    primary: '#7D70F6',
    text: 'rgba(58,52,51,1)',
    textSecondary: 'rgba(58,52,51,0.7)',
    background: 'rgba(255,255,255,1)',
    backgroundVariant: 'rgba(251,249,249,1)',
    border: 'rgba(58,52,51,0.12)',
    borderLight: 'rgba(58,52,51,0.05)',
    navColor: '#F78E1E',
};

export type Theme = typeof lightTheme;

export const themes = {
    light: lightTheme,
    dark: darkTheme,
};

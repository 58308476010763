import React, { useState } from 'react';
import axios from 'axios';

import { URL } from 'utils/constant';
import { useMountEffect } from 'hook/useMountEffect';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { LoadingWrapper } from 'app/pages/LandingPage/LandingPage.style';

type CheckTosProps = {
    children: React.ReactChild;
};

const CheckTos = ({ children }: CheckTosProps) => {
    const [landingTos, setLandingTos] = useState(true);
    useMountEffect(() => {
        (async () => {
            let shouldLandingTos = false;
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_DEFI_API}/hasSignedTos`, {
                    withCredentials: true,
                });
                shouldLandingTos = !data?.result;
            } catch (error) {
                shouldLandingTos = false;
            }
            setLandingTos(shouldLandingTos);
            if (shouldLandingTos) {
                window.location.href = process.env.PUBLIC_URL + URL.Tos;
            }
        })();
    });
    if (!landingTos) return <>{children}</>;
    else
        return (
            <LoadingWrapper>
                <LoadingIndicator />
            </LoadingWrapper>
        );
};

export default CheckTos;

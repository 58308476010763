import styled, { keyframes } from 'styled-components/macro';
import { ScreenSizes } from 'styles/StyleConstants';
import background from './assets/landing_background.png';
import iconBackground from './assets/landing.svg';
import companyBackground from './assets/company_background.png';

const fadeInLeft = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
`;

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    margin-top: -5px;
`;

export const LandingLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 115px;
    padding: 0 5%;
    margin-bottom: 100px;

    .lend-div-1 {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* max-width: 700px; */
    }

    .lend-div-2 {
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 46px;
        color: #ffffff;
        white-space: pre-line;
        transition: all ease 0.3s;
        animation: ${fadeIn} ${1}s linear;
        z-index: 2;

        span {
            font-weight: 500;
        }

        a {
            color: #c8c3f9;
            text-decoration: unset;
            &:hover {
                cursor: pointer;
                color: #7d70f6;
                transition: all ease 0.3s;
            }
        }
    }

    .lend-div-3-wrap {
        width: 100%;
        animation: ${fadeIn} ${1}s linear;
    }

    .lend-div-3 {
        width: 144px;
        height: 42px;
        border: 2px solid #7d70f6;
        border-radius: 9px;
        margin-top: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        align-items: center;
        text-transform: uppercase;
        color: #ffff;
        background-color: #7d70f6;
        transition: all ease 0.5s;
        text-transform: uppercase;
        user-select: none;

        &:hover {
            cursor: pointer;
            color: #ffffff;
            border: 2px solid #ffffff;
            transition: all ease 0.5s;
            background-color: unset;
        }
    }

    .lend-div-4 {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* background: url(${iconBackground}) no-repeat center;
        background-size: 600px; */
        animation: ${fadeIn} ${3}s linear;
    }

    .lend-div-5 {
        background: linear-gradient(
            95.14deg,
            rgba(255, 171, 81, 0.5) 0%,
            rgba(154, 144, 252, 0.75) 100%
        );
        backdrop-filter: blur(15px);
        border-radius: 10px;
        padding: 12px 24px;
        user-select: none;

        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 56px;
    }

    .lend-div-6 {
        margin-top: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        text-align: right;
        text-transform: uppercase;
        color: #c8c3f9;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        margin-top: 10px;
        flex-direction: column;
        margin-bottom: 10px;

        .lend-div-1 {
            margin: 60px 0px 40px 0px;
            padding-right: 5%;
            margin-right: 0px;
            margin-bottom: 0px;
            width: 90%;
            justify-content: center;
            align-items: center;
            max-width: unset;
        }

        .lend-div-2 {
            width: 90%;
            white-space: unset;
        }

        .lend-div-3-wrap {
            width: 90%;
        }

        .lend-div-4 {
            width: 90%;
            align-items: center;
            padding-left: unset;
            background-size: unset;
        }
    }

    @media (max-width: ${ScreenSizes.small}) {
        .lend-div-4 {
            margin-top: 0px;
        }

        .lend-div-5 {
            font-weight: 500;
            font-size: 36px;
            line-height: 46px;
            margin-top: 10px;
        }
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        .lend-div-1 {
            margin: 10px;
        }
        .lend-div-2 {
            width: 90%;
            font-weight: 300;
            font-size: 26px;
            line-height: 36px;
        }

        .lend-div-4 {
            margin-top: 0px;
            margin-left: 10px;
        }

        .lend-div-5 {
            font-weight: 500;
            font-size: 36px;
            line-height: 46px;
            margin-top: 10px;
        }
    }
`;

export const InfoLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -50px;
    margin-bottom: 50px;

    .info-div-1 {
        width: 991px;
        height: 100px;
        background: linear-gradient(95.14deg, #361c49 0%, #51477b 100%);
        box-shadow: 0px 4px 20px rgba(73, 65, 140, 0.15);
        border-radius: 10px;
        padding: 27px 40px;
        animation: ${fadeInUp} ${2}s linear;

        display: flex;
        justify-content: space-between;
        margin-top: 17px;
    }

    .info-div-2 {
        width: 20%;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        padding-left: 20px;
    }

    .info-div-2.first {
        border-left: unset;
    }

    .info-div-3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        align-items: center;
        color: #ffffff;
        text-transform: uppercase;
    }

    .info-div-4 {
        margin-top: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        color: #c8c3f9;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        margin-top: 100px;
        margin-bottom: 50px;
        .info-div-1 {
            width: 60%;
            height: auto;
            padding: 27px 40px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .info-div-2 {
            padding: 13px;
            width: 100%;
            border-left: unset;
            border-bottom: 1px solid #e2e6eb;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .info-div-2.last {
            width: 100%;
            border: unset;
        }
    }

    @media (max-width: ${ScreenSizes.small}) {
        margin-top: 30px;
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        margin-top: 70px;
        margin-bottom: 20px;
        .info-div-1 {
            width: 90%;
            height: auto;
        }
    }
`;

export const LearnMoreTipLayout = styled.div`
    width: 100%;
    margin-top: 150px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff;

    .tip-div-1 {
        width: 290px;
        margin-bottom: 20px;
        margin-right: 26px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .tip-div-2 {
        font-weight: 300;
        font-size: 46px;
        color: #ffffff;
        white-space: break-spaces;
        display: flex;
        flex-direction: column;
    }

    .tip-div-2 span {
        color: #c8c3f9;
    }

    .tip-div-2-sub {
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #ebe9fa;
    }

    .tip-div-3 {
        width: 144px;
        height: 42px;
        border: 2px solid #ffffff;
        border-radius: 9px;
        margin-top: 24px;

        display: flex;
        justify-content: center;
        align-items: center;

        align-items: center;
        text-transform: uppercase;
        color: #ffffff;
        transition: all ease 0.5s;
        text-transform: uppercase;
        user-select: none;

        &:hover {
            cursor: pointer;
            color: #7d70f6;
            border: 2px solid #7d70f6;
            transition: all ease 0.5s;
        }
    }

    .tip-div-5 {
        width: 300px;
        margin: 0px 7px 7px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .tip-div-5-wrap {
        display: flex;
        jutify-content: center;
        align-items: center;
        position: relative;
        width: 270px;
        height: 270px;
        padding: 20px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50.21%, rgba(0, 0, 0, 0.7) 100%);
        border-radius: 20px;

        .tip-img-1 {
            width: 100%;
        }
        .tip-img-1.reliabilityIcon {
            width: 70%;
            margin-left: 16%;
        }
    }

    .tip-div-5-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #c8c3f9;
        display: flex;
        justify-content: center;
        align-items: center;

        .tip-img-2 {
            width: 20px;
            height: 20px;
        }

        &:hover {
            cursor: pointer;
            background: #7d70f6;
            transition: all ease 0.7s;
        }
    }

    .tip-div-6 {
        margin-top: 19px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: #fff;
    }

    .tip-div-7 {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #ebe9fa;
    }

    .tip-div-8 {
        width: 270px;
        margin-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #ebe9fa;
        min-height: 70px;
    }

    .tip-div-9 {
        margin-top: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        color: #c8c3f9;
        transition: all ease 0.3s;

        &:hover {
            cursor: pointer;
            color: #7d70f6;
            transition: all ease 0.3s;
        }
    }

    @media (max-width: ${ScreenSizes.medium}) {
        flex-direction: column;
        margin-top: 55px;
        align-items: center;
        .tip-div-1 {
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .tip-div-2 {
            width: 90%;
            display: flex;
            justify-content: center;
        }

        .tip-div-2-sub {
            width: 90%;
            display: flex;
            justify-content: center;
        }

        .tip-div-3 {
            margin-top: 22px;
        }

        .tip-div-3-wrap {
            width: 90%;
            display: flex;
            justify-content: center;
        }

        .tip-div-5 {
            width: 90%;
            margin: 20px 20px;
            justify-content: flex-start;
            align-items: center;
        }

        .tip-div-8 {
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        margin-top: 25px;
        .tip-div-2 {
            width: 80%;
            justify-content: flex-start;
            text-align: left;
            font-weight: 300;
            font-size: 26px;
            line-height: 36px;
        }

        .tip-div-2-sub {
            width: 80%;
            display: flex;
            justify-content: flex-start;
        }

        .tip-div-3-wrap {
            width: 80%;
            display: flex;
            justify-content: flex-start;
        }

        .tip-div-5 {
            width: 90%;
            margin: 20px 20px;
        }

        .tip-div-5-wrap {
            max-width: unset;
        }
    }
`;

export const StepIntroLayout = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .step-img-1 {
        width: 648px;
        height: 355px;
        margin-top: 50px;
    }

    .step-div-1 {
        margin-top: 74px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 600px;
    }

    .step-div-2 {
        margin-top: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
    }

    .step-div-3 {
        width: 700px;
        margin-top: 24px;
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        color: #fff;
        text-align: center;
    }

    .step-div-3 span {
        color: #d39ead;
    }

    .step-div-4 {
        text-align: center;
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #ebe9fa;
        max-width: 400px;
    }

    .step-div-6 {
        margin-top: 171px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 42px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step-div-7 {
        max-width: 500px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .step-div-8 {
        font-weight: 300;
        font-size: 46px;
        color: #d39ead;
        line-height: 55px;
        white-space: break-spaces;
    }

    .step-div-8 span {
        color: #fff;
    }

    .step-div-9 {
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #ebe9fa;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        justify-content: center;
        align-items: center;

        .step-img-1 {
            width: 100%;
            height: unset;
        }

        .step-div-1 {
            margin-top: 23px;
        }

        .step-div-3 {
            margin-top: 14px;
            font-size: 26px;
            width: unset;
        }

        .step-div-6 {
            flex-direction: column;
            margin-top: 40px;
        }

        .step-div-7 {
            width: 90%;
        }
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        .step-div-8 {
            font-size: 26px;
            line-height: 30px;
        }

        .step-div-2,
        .step-div-3,
        .step-div-4 {
            width: 90%;
            text-align: left;
        }

        .step-div-6 {
            width: 90%;
            text-align: left;
        }
    }
`;

export const StepDetailLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    width: 100%;
    margin: 135px 0px;

    .step-div-1 {
        display: flex;
        justify-content: center;
        align-items: start;
    }

    .step-div-2 {
        width: 60px;
        height: 60px;
        background: #65556c;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        margin-right: 24px;
    }

    .step-div-3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .step-div-4 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 16px;
        transition: color 0.3s;
    }

    .step-div-4:hover {
        color: #c8c3f9;
        transition: color 0.3s;
    }

    .step-div-5 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #d39ead;
        max-width: 200px;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        margin: 100px 0px;
        .step-div-1 {
            width: 40%;
        }
    }

    @media (max-width: ${ScreenSizes.small}) {
        margin: 100px 0px;
        .step-div-1 {
            width: 80%;
            margin: 13px 0px;
        }
    }
`;

export const ComWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${companyBackground});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    margin-top: -5px;

    @media (max-width: ${ScreenSizes.large}) {
        flex-direction: column-reverse;
    }
`;

export const CompanyListLayout = styled.div`
    width: 50%;
    display: flex;
    justify-contents: center;
    align-items: center;
    flex-direction: column;
    margin: 240px 30px 240px 0px;

    .company-div-wrap {
        display: flex;
        justify-contents: center;
        align-items: center;
        margin-left: 100px;
        margin-bottom: 8px;
    }

    .company-div-wrap.left {
        margin-left: -100px;
    }

    .company-div-1 {
        width: 288px;
        height: 80px;

        display: flex;
        justify-contents: center;
        align-items: center;
        padding: 10px 15px;
        .space: {
            margin: 0px 12px;
        }
    }

    .end-show {
        display: none;
    }

    .company-img-1 {
        width: 258px;
        height: 71px;
    }

    @media (max-width: ${ScreenSizes.large}) {
        .company-img-1 {
            width: 100%;
        }
        width: 90%;
        margin: 50px 0px;
    }

    @media (max-width: ${ScreenSizes.medium}) {
        width: 90%;
        margin: 50px 0px;

        .company-div-wrap {
            margin-left: 0px;
        }

        .company-div-wrap.left {
            margin-left: 0px;
        }
    }

    @media (max-width: ${ScreenSizes.small}) {
        width: 90%;
        margin: 50px 0px;

        .end-hidden {
            display: none;
        }

        .end-show {
            display: block;
        }

        .company-div-1 {
            width: 220px;
            padding: 10px 10px;
        }
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        .company-div-wrap {
            flex-direction: column;
        }
    }
`;

export const IntroCompany = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 280px;
    margin-left: 40px;

    .introcom-div-1 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: right;
        text-transform: uppercase;
        color: #ffffff;
    }

    .introcom-div-2 {
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        text-align: right;
        line-height: 56px;
        color: #ffffff;
        margin-top: 16px;
    }

    .introcom-div-2 span {
        color: #c8c3f9;
    }

    .introcom-div-3 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        text-align: right;
        color: #e1dffa;
        margin-top: 24px;
    }

    .introcom-div-4 {
        width: 150px;
        height: 42px;
        background: #7d70f6;
        border-radius: 9px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 24px;
        transition: all ease 0.3s;

        &:hover {
            cursor: pointer;
            color: #7d70f6;
            background-color: #fff;
            transition: all ease 0.3s;
        }
    }
    @media (max-width: ${ScreenSizes.large}) {
        width: 90%;
        margin: 30px 0px;
        align-items: flex-start;
        .introcom-div-2 {
            text-align: left;
        }
    }

    @media (max-width: ${ScreenSizes.medium}) {
        width: 90%;
        .introcom-div-1 {
            text-align: left;
        }
        .introcom-div-3 {
            text-align: left;
        }
    }

    @media (max-width: ${ScreenSizes.mobile}) {
        width: 80%;
        .introcom-div-2 {
            font-size: 36px;
            line-height: 46px;
        }
    }
`;

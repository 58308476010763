import { notification } from 'antd';
import BigNumber from 'bignumber.js';

const errorMessagesMapping = {
    'is greater than the maximum billable CPU time for the transaction':
        "Whoops! Your CPU is low — head over <a target='_blank' href='https://wallet.wax.io/dashboard'>here</a> and click on Resources to stake some WAXP to your CPU to continue the fun!  Don't worry, you can unstake your tokens at any time. <br>" +
        "<a target='_blank' href='https://wax-io.medium.com/psa-understanding-cpu-usage-and-fluctuations-in-accordance-with-staking-on-wax-84f88ef03f5f'>Learn more</a> about how staking CPU keeps our blockchain running smoothly without all the fees.",
    'has insufficient ram;':
        'You do not have sufficient RAM to complete this transaction. Please stake more WAX in RAM at the Wax Cloud Wallet, stake page.',
    'metamask tx signature: user denied transaction signature':
        'Whoops! It looks like this transaction was not approved within Metamask. Please double-check your transaction and try again.',
    'expired transaction':
        'Uh-oh. It appears your transaction exceeded the allotted time. Please try your transfer again.',
    'transaction has been reverted by the evm':
        "Hey, it looks like you've already claimed that transaction. Please double-check the transaction you are looking to claim.",
    'assertion failure with message: collection_name does not exist in colmap table':
        'Whoops. That collection name does not exist, please double-check the name and try again.',
    'metamask tx signature: user denied transaction signature.':
        'Whoops. This transaction was declined by Metamask. Please double-check your transaction and try again.',
    'user must stake before they can vote':
        "Want to vote? You'll need to stake some WAXP first. Visit the Resources tab to get started.",
    "returned values aren't valid, did it run out of gas? you might also see this error if you are not using the correct abi for the contract you are retrieving data from, requesting data from a block number that does not exist, or querying a node which is not fully synced.":
        'Whoops. Looks like something went wrong. Please double-check you have allotted enough gas and are using the correct contract information, then try again.',
    "request of type 'wallet_switchethereumchain' already pending for origin":
        'Please check MetaMask and confirm changing to the network.',
    "request of type 'wallet_addethereumchain' already pending for origin":
        'Please check Metamask and allow it to add a new network.',
    "request of type 'wallet_requestpermissions' already pending for origin":
        'Please check Metamask and connect to your account.',
};

const shortenEthAddress = (ethAddress: string): string => {
    if (ethAddress.length > 12) {
        return ethAddress
            ? `${ethAddress.substring(0, 5)}...${ethAddress.substring(
                  ethAddress.length - 4,
                  ethAddress.length,
              )}`
            : '';
    } else {
        return ethAddress;
    }
};
const eth2Scope = (ethereumAddress: string): string => {
    const a_to_f = '.12345';
    const zero_to_9 = 'abcdefghij';
    let scope = '';
    for (let i = 2; i < ethereumAddress.length; i++) {
        let c = ethereumAddress[i];
        if (c >= '0' && c <= '9') {
            const i = c.charCodeAt(0) - '0'.charCodeAt(0);
            scope += zero_to_9[i];
        } else if (c >= 'A' && c <= 'F') {
            const i = c.charCodeAt(0) - 'A'.charCodeAt(0);
            scope += a_to_f[i];
        } else if (c >= 'a' && c <= 'f') {
            const i = c.charCodeAt(0) - 'a'.charCodeAt(0);
            scope += a_to_f[i];
        }
        if (scope.length >= 12) {
            break;
        }
    }
    while (scope.endsWith('.')) {
        scope = scope.slice(0, scope.length - 1);
    }
    return scope;
};

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const conversions = {
    // WAXP_1000: [{ rate: 1000, to: "ETHEREUM_WAXE" }],
    // WAXP_1: [{ rate: 1, to: "ETHEREUM_WAXP" }],
    ETHEREUM_WAXE: [{ rate: 0.001, from: 'WAX_WAXP', precision: 8 }],
    ETHEREUM_WAXP: [{ rate: 1, from: 'WAX_WAXP', precision: 8 }],
};

const decimalUtil = function () {
    function fromNDecimals(n: number, strValue: string) {
        strValue = '' + strValue;
        let decimalAt = strValue.indexOf('.');
        if (decimalAt < 0) {
            decimalAt = strValue.length - 1;
        }
        const pad = n - (strValue.length - decimalAt) + 1;
        let strNoDecimal = strValue.replace('.', '');
        if (pad < 0) {
            throw new Error(
                `Tried to convert ${strValue} to ${n} decimals when it has more than that`,
            );
        }
        strNoDecimal = strNoDecimal.padEnd(strNoDecimal.length + pad, '0');
        return strNoDecimal.replace(/^0+/, '');
    }

    function toNDecimals(n: number, strValue: string, remove_trails: boolean = true) {
        // turn it into a string if it isn't already
        strValue = '' + strValue;

        // remove any decimals
        const decimalPoint = strValue.indexOf('.');
        if (decimalPoint > 0) {
            strValue = strValue.split('.')[0];
        } else if (decimalPoint === 0) {
            strValue = '0';
        }

        // expand it with leading zeroes if not enough
        strValue = strValue.padStart(n + 1, '0');

        // insert the decimal at nth precision
        strValue = strValue.slice(0, -n) + '.' + strValue.slice(-n);

        // remove any trailing zeroes for compact display
        if (remove_trails) {
            strValue = strValue.replace(/0+$/g, '');

            // if no trailing decimals, remove the trailing decimal point
            strValue = strValue.replace(/\.+$/g, '');
        }
        return strValue;
    }

    function toGeneralNumberValue(strValue: string, tokenName: string = '', precision: number = 2) {
        let formattedValue = `0`;
        try {
            strValue && (formattedValue = toActualNumberValue(strValue, precision));
        } catch {}
        return formattedValue + (tokenName ? ` ${tokenName}` : ``);
    }

    function toActualNumberValue(strValue: string, precision: number = 8) {
        const value = new BigNumber(strValue).toNumber();
        const formattedNumber = new Intl.NumberFormat('en', {
            maximumFractionDigits: precision,
        }).format(value);
        return formattedNumber;
    }

    function convertToInternationalCurrencySystem(value) {
        const units = ['M', 'B', 'T', 'Q'];
        const unit = Math.floor((value / 1.0e1).toFixed(0).toString().length);
        const r = unit % 3;
        const x = Math.abs(Number(value)) / Number('1.0e+' + (unit - r));
        return parseFloat(x.toFixed(2)) + ' ' + units[Math.floor(unit / 3) - 2];
    }

    function fromDecimalToHex(value: number) {
        return `0x${value.toString(16)}`;
    }

    return {
        from18Decimals(strValue: string) {
            return fromNDecimals(18, strValue);
        },
        from8Decimals(strValue: string) {
            return fromNDecimals(8, strValue);
        },
        to8Decimals(strValue: string) {
            return toNDecimals(8, strValue);
        },
        to18Decimals(strValue: string) {
            return toNDecimals(18, strValue);
        },
        toGeneralNumberValue(strValue: string, tokenName: string = '', precision: number = 2) {
            return toGeneralNumberValue(strValue, tokenName, precision);
        },
        toActualNumberValue(strValue: string, precision: number = 8) {
            return toActualNumberValue(strValue, precision);
        },
        fromDecimalToHex(value: number) {
            return fromDecimalToHex(value);
        },
        convertToInternationalCurrencySystem(value: number) {
            return convertToInternationalCurrencySystem(value);
        },
    };
};

const translateError = msg => {
    let m = msg.toLowerCase();
    const foundKey = Object.keys(errorMessagesMapping).find(k => m.indexOf(k) !== -1);
    return foundKey ? errorMessagesMapping[foundKey] : msg;
};

const showSuccess = (message: string) => {
    notification.success({
        message: '',
        description: translateError(message),
    });
};

const showWarning = (message: string) => {
    notification.warning({
        message: '',
        description: translateError(message),
    });
};

const importTokenToMetaMask = (
    ethWindow: any,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number,
    tokenImage: string,
) => {
    if (ethWindow) {
        return ethWindow.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: tokenAddress,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    image: tokenImage,
                },
            },
        });
    }
};

const connectToMetaMask = (ethWindow: any, CHAIN_ID: number) => {
    if (!!ethWindow) {
        if (ethWindow.networkVersion !== CHAIN_ID.toString()) {
            return switchNetwork(ethWindow, CHAIN_ID).then(() => {
                ethWindow.request({ method: 'eth_requestAccounts' });
            });
        } else {
        }
        return ethWindow.request({ method: 'eth_requestAccounts' });
    }
};

const switchNetwork = (ethWindow: any, chainId: number) => {
    const ethereum = ethWindow ?? window.ethereum;
    const ethExist = !!ethereum;
    const isInCorrectNetworkVersion = ethExist && ethereum.networkVersion === chainId;

    if (document.visibilityState === 'visible' && ethExist && !isInCorrectNetworkVersion) {
        return ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: decimalUtil().fromDecimalToHex(chainId) }],
        });
    }
};
class TokenBalance {
    tokenName: string;

    /**
     * default precision of a crypto currency in their tokenomic ex WAXP'precision = 8
     * */
    tokenPrecision: number;

    /**
     * Balance derived & calculated from contract
     */
    rawBalance: number;
    util;

    constructor(tokenName: string, rawBalance: number, tokenPrecision: number) {
        this.tokenName = tokenName;
        this.tokenPrecision = tokenPrecision;
        this.rawBalance = rawBalance;
        this.util = decimalUtil();
    }

    /**
     * example "11.23 WAXP"
     */
    toShortInfo(): string {
        const shortFormatBalance = this.util.toActualNumberValue(this.rawBalance, 4);
        return `${shortFormatBalance} ${this.tokenName}`;
    }

    /**
     * example "11.232323232 WAXP"
     */
    toFullInfo(): string {
        return `${this.toDecimal()} ${this.tokenName}`;
    }

    /**
     * actual string format of balance using in calculation
     * example "11.23232323"
     */
    toDecimal(): string {
        return this.util.toActualNumberValue(this.rawBalance, this.tokenPrecision);
    }

    toRaw(): number {
        return this.rawBalance;
    }
}

export {
    shortenEthAddress,
    eth2Scope,
    sleep,
    conversions,
    decimalUtil,
    showSuccess,
    showWarning,
    importTokenToMetaMask,
    switchNetwork,
    TokenBalance,
    connectToMetaMask,
};

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { saga } from './saga';
import { UserState, ErrorType, UserData, MetaMaskData } from './types';

export const initialState: UserState = {
    username: '',
    loadingWcw: false,
    loadingWallets: false,
    error: null,
    walletData: null,
    metamaskData: null,
    isOpenLoginModal: false,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        //get session of wcw
        getSession(state) {
            state.loadingWcw = true;
        },
        //had data after get seesion
        gotSession(state, action: PayloadAction<UserData>) {
            state.loadingWcw = false;
            state.walletData = action.payload;
            state.username = action.payload.username;
        },
        //sign out for wcw
        signOut(state) {
            state.username = '';
            state.loadingWcw = false;
            state.error = null;
            state.walletData = null;
        },
        //sign in for wcw
        signIn(state) {
            state.loadingWcw = false;
        },
        //error when login wcw
        errorSign(state, action: PayloadAction<ErrorType>) {
            state.error = action.payload;
            state.loadingWcw = false;
        },
        //get meatask data when initialize
        getMetamask(state) {
            state.loadingWallets = true;
        },
        metaMaskConnecting(state) {
            state.loadingWallets = true;
        },
        metaMaskConnected(state, action: PayloadAction<MetaMaskData>) {
            state.metamaskData = action.payload;
            state.loadingWallets = false;
        },
        metaMaskDisConnected(state) {
            if (!!state.metamaskData) {
                if (!!state.metamaskData.ethWc) {
                    state.metamaskData.ethWindow = null;
                    state.metamaskData.ethAddress = '';
                } else {
                    state.metamaskData = null;
                }
            }
            state.loadingWallets = false;
        },
        walletConnectConnecting(state) {
            state.loadingWallets = true;
        },
        walletConnectConnected(state, action: PayloadAction<MetaMaskData>) {
            state.metamaskData = action.payload;
            state.loadingWallets = false;
        },
        walletConnectDisConnected(state) {
            if (!!state.metamaskData) {
                if (!!state.metamaskData.ethWc) {
                    state.metamaskData.ethWc = null;
                    state.metamaskData.ethAddress = '';
                } else {
                    state.metamaskData = null;
                }
            }
            window.wc = null;
            state.metamaskData = null;
            state.loadingWallets = false;
        },
        openLoginModal(state) {
            state.isOpenLoginModal = true;
        },
        closeLoginModal(state) {
            state.isOpenLoginModal = false;
        },
    },
});

export const { actions: UserActions, reducer } = slice;

export const useUserSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga });
    return { actions: slice.actions };
};

import { URL } from 'utils/constant';
import { createContext, useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import wLog from 'utils/logger';

type AuthContextProps = {
    accountName: string;
    signIn?: VoidFunction;
    signOut?: VoidFunction;
};

export const AuthContext = createContext<AuthContextProps>({
    accountName: '',
});

export function AuthProvider({ children }) {
    const [accountName, setAccountName] = useState<string>('');
    let popup = useRef<Window | null>(null);

    useEffect(() => {
        window.addEventListener('message', event => {
            if (
                popup.current &&
                event.origin === window.location.origin &&
                event.data === 'AUTH_LOGIN_SUCCESS'
            ) {
                popup.current.close();
                window.location.reload();
            }
        });
    }, []);

    useEffect(() => {
        const getSession = async () => {
            try {
                const { data } = await axios(`${process.env.REACT_APP_DEFI_API}/session`, {
                    method: 'GET',
                    withCredentials: true,
                });

                if (data.accountName) {
                    setAccountName(data.accountName);

                    window.opener.postMessage('AUTH_LOGIN_SUCCESS', window.location.origin);
                }
            } catch (error: any) {
                if (error.response) {
                    wLog.log(error.response?.data);
                }
            }
        };

        getSession();
    }, []);

    function signIn() {
        popup.current = window.open(
            `${URL.Login}`,
            'Login via All Access',
            'height=625,width=700,left=100,top=100,resizable=no,scrollbars=no,titlebar=no,status=no' +
                ',toolbar=no,menubar=no,location=no,directories=no',
        );
    }

    async function signOut() {
        //window.location.href = `${process.env.REACT_APP_DEFI_API}/logout`;
        await axios(`${process.env.REACT_APP_DEFI_API}/logout`, {
            method: 'POST',
            withCredentials: true,
        });
    }

    return (
        <AuthContext.Provider
            value={{
                accountName,
                signOut,
                signIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}

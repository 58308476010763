import { configureStore, getDefaultMiddleware, StoreEnhancer } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';

export function configureAppStore() {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;

    // Create the store with saga middleware
    const middlewares = [sagaMiddleware];

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ] as StoreEnhancer[];

    const customizedMiddleware = getDefaultMiddleware({
        serializableCheck: false,
    });

    const store = configureStore({
        reducer: createReducer(),
        middleware: [...customizedMiddleware, ...middlewares],
        devTools:
            /* istanbul ignore next line */
            process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
        enhancers,
    });

    return store;
}

import { JsonRpc } from 'eosjs/dist';
import { CONTRACTS, WAX_CHAIN_API } from './constant';

export default class WaxRpcHandler {
    rpc: JsonRpc;
    constructor() {
        this.rpc = new JsonRpc(WAX_CHAIN_API, { fetch });
    }

    async getWaxBalance(userAccount: string) {
        const res = await this.rpc.get_table_rows({
            json: true,
            code: 'eosio.token',
            scope: userAccount,
            table: 'accounts',
            limit: 1,
            reverse: false,
            show_payer: false,
        });
        if (res.rows && res.rows.length > 0) return res.rows[0].balance;
        else return 0;
    }

    async getEthClaimsData(userAccount: string) {
        const res = await this.rpc.get_table_rows({
            json: true,
            code: CONTRACTS.WAX_BRIDGE,
            scope: userAccount,
            table: 'claims.b',
            limit: 1000,
            reverse: false,
            show_payer: false,
        });

        return res.rows;
    }

    async getErc20Decimals(erc20Address): Promise<number> {
        const res = await this.rpc.get_table_rows({
            json: true,
            code: 'eth.token',
            scope: 'eth.token',
            table: 'ethmap',
            limit: 1,
            reverse: false,
            show_payer: false,
        });
        const erc20 = res.rows.find(r => r.eth_token_address === erc20Address);
        if (!erc20) {
            throw new Error(`ERC20 ${erc20Address} does not exist`);
        }
        return parseInt(erc20.token_symbol.split(',')[0]);
    }

    async getTokenWaxSideBalance(
        tokenSymbol: string | string[],
        userAccount: string,
        tokenContract: string,
    ) {
        const res = await this.rpc.get_table_rows({
            json: true,
            code: tokenContract,
            scope: userAccount,
            table: 'accounts',
            limit: 1000,
            reverse: false,
            show_payer: false,
        });
        let { rows } = res;
        if (rows.length > 0) {
            if (typeof tokenSymbol === 'string') {
                for (let i = 0; i < rows.length; i++) {
                    let arr = rows[i].balance.split(' ');
                    if (tokenSymbol === arr[1]) return arr[0];
                }
            } else {
                let out: string[] = [];
                for (let i = 0; i < rows.length; i++) {
                    let arr: string[] = rows[i].balance.split(' ');
                    for (let x = 0; x < tokenSymbol[x].length; x++) {
                        if (tokenSymbol[x] === arr[1]) out.push(arr[0]);
                    }
                }
                return out;
            }
        }
        return 0;
    }
}

export const jsonRpc = new JsonRpc(WAX_CHAIN_API, { fetch });

export async function getWaxBalance(userAccount: string) {
    const res = await jsonRpc.get_table_rows({
        json: true,
        code: 'eosio.token',
        scope: userAccount,
        table: 'accounts',
        limit: 1,
        reverse: false,
        show_payer: false,
    });
    if (res.rows && res.rows.length > 0) return parseFloat(res.rows[0].balance);
    else return 0;
}
